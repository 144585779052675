import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby'
import Layout from "templates/basic"
import Intro from 'components/intro'
import SEO from 'components/seo'
import style from 'templates/ContentPageGet/ContentPageGet.module.scss'
import parse from 'html-react-parser';

const ContentPageGet = ({ data, pageContext }) => {
	let page = null;
	if (data.allContentPage && data.allContentPage.edges.length) page = data.allContentPage.edges[0].node;

	if (!page) {
		return (
			<Layout>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className={`${style.nothingHere} cell small-12`}>
							<h1>Nothing to see here</h1>
							<p>The page that you are looking for has either moved or no longer exists.</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}

	const [newPage, setPage] = useState(page);

	useEffect(() => {
		fetch(`${process.env.GATSBY_ZMS_API_URL}content/page/get/?uri=${page.uri}`)
			.then(response => response.json())
			.then(({ data }) => {
				if (data.length) {
					let page = data[0];
					setPage(page);
				}
			})
			.catch(err => console.log(err));
	}, [page.uri]);

	page = newPage;

	return (
		<>
			<Layout image={page.headerImage} preTitle={page.pageTitle} title={page.subTitle} link={page.link} linkText={page.linkText} enableDanosFoundation={page.danosFoundation} enableFooterTabs={page.danosFooterTab}>
				<Intro pullQuote={page.pullQuote} headline={page.headline} pageCopy={page.pageCopy} />
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							{page.pageCopy && <hr style={{ margin: "60px auto" }} />}
							<div className={style.innerContent}>{page ? parse(page.content.main) : ''}</div>
						</div>
					</div>
				</div>
			</Layout>
			<SEO title={page ? page.pageTitle : ''} />
		</>
	)
}

export default ContentPageGet

export const query = graphql`
	query ($uri: String, $lang: String) {
	  allContentPage(filter: {uri: {eq: $uri}, lang: {eq: $lang}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
			lang
	        pageTitle
			subTitle
			pullQuote
			pageCopy
			headline
			link
			linkText
			danosFoundation
			danosFooterTab
			headerImage
	      }
	    }
	  }
	}
`